import { HttpErrorResponse } from '@angular/common/http';
import { Component, effect, OnInit } from '@angular/core';
import { CreateQueryResult } from '@tanstack/angular-query-experimental';
import { PrimeNGConfig } from 'primeng/api';
import { TranslationService } from '@/modules/i18n';
import { User } from '@/pages/auth/models';
import { AuthService } from '@/pages/auth/services/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  protected readonly environment = environment;

  queryProfile: CreateQueryResult<User, HttpErrorResponse> = this._authService.getProfile();

  constructor(
    // Enabled by injection
    private _translationService: TranslationService,
    private _primengConfig: PrimeNGConfig,
    private _authService: AuthService,
  ) {
    effect(() => {
      if (this.queryProfile.data()) {
        this._authService.updateUser(this.queryProfile.data());
        this._translationService.setLanguage(this.queryProfile.data()!.locale || 'es');
      }
    });
  }

  ngOnInit(): void {
    this._primengConfig.ripple = false;
  }
}
